<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="values"
      :items-per-page="itemsPerPage"
      class="elevation-0"
      hide-default-footer
    />
    <v-pagination
      v-if="totalPage && totalPage > 1"
      v-model="page"
      :length="totalPage"
      :total-visible="itemsPerPage"
      @input="handleGetData"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apiWF from '@/services/apiWelcomeForm'
export default {
  data: () => ({
    itemsPerPage: 10,
    totalPage: null, 
    page: 1,
    count: 0,
    values: [],
  }),
  computed: {
    ...mapState('app',['dtouchWorkspace', 'locale']),
    startIndex () {
      return (this.page -1) * this.itemsPerPage
    },
    headers () {
      return [
        {
          text: this.$t('dtouch.welcomeForm.name', this.locale),
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: this.$t('dtouch.welcomeForm.phone', this.locale),
          align: 'start',
          sortable: false,
          value: 'Phone',
        },
        {
          text: this.$t('dtouch.welcomeForm.email', this.locale),
          align: 'start',
          sortable: false,
          value: 'Email',
        },
      ]
    },
  },
  watch: {
    dtouchWorkspace () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      if (!this.dtouchWorkspace) return
      
      const filters = {
        startIndex: this.startIndex,
        itemsPerPage: this.itemsPerPage,
      }

      apiWF.getResult(this.dtouchWorkspace, filters)
        .then(response => {
          if (!response) return
          this.count = response.count
          this.totalPage = Math.ceil(this.count / this.itemsPerPage)
          this.values = response.data
        })
        .catch(error => {
          console.log('jhm, err', error)
        }) 
    },
  },
}
</script>

