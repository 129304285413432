import axios from 'axios'
const service = {
  getToken () {
    const token = localStorage.getItem('jwt')
    return token && token.length > 0 ? token : null
  },
  getBaseURL () {
    return `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}`.replace('https://dtouch.es/', 'https://www.dtouch.es')
  },
  async getFormFields (workspaceID) {
    const baseURL = this.getBaseURL()
    const url = `${baseURL}/v1/public/workspaces/${workspaceID}/welcome-form`
    return await new Promise((resolve) => axios.get(url)
      .then(response => {
        if (!response || !response.data) resolve(null)
        else {
          const data = response.data.shift()
          resolve(data ? data.config : null)
        }
      }))
  },
  async sendResult (workspaceID, body) {
    const baseURL = this.getBaseURL()
    const url = `${baseURL}/v1/public/workspaces/${workspaceID}/welcome-form`
    return await new Promise((resolve) => axios.post(url, body)
      .then(response => {
        console.log(response)
        if (response) resolve(true)
        else resolve(false)
      })
      .catch (error => {
        console.log('error', error)
        resolve(false)
      })
    )
  },
  getResult (workspaceID, body) {
    const baseURL = this.getBaseURL()
    let url = `${baseURL}/v1/dtouch/workspaces/${workspaceID}/welcome-form-result`
    if (this.getToken()) url += `?token=${this.getToken()}`

    return axios.post(url, body)
      .then(response => response.data)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },
}

export default service

